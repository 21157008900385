import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useStores } from "modules/stores";
import keycode from "keycode";
import { useHistory, Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { useObserver } from "mobx-react-lite";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableFooter from "@material-ui/core/TableFooter";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

import CircularProgress from "components/CircularProgress";
import AppBar from "@material-ui/core/AppBar";
import SearchBox from "components/SearchBox";
import Avatar from "components/table/Avatar";
import Error from "components/Errors/Error";
import moment from "moment";
import Fab from "@material-ui/core/Fab";
import { SortableContainer, SortableHandle, SortableElement } from "react-sortable-hoc";
import IntlMessages from "util/IntlMessages";
import { isObjectsEqual, saveFile } from "util/helpers";
import Box from "@material-ui/core/Box";

import Button from "@material-ui/core/Button";
import CardBox from "components/CardBox/index";
import { useIntl } from "react-intl";
import EditField from "components/table/EditField";
import Collapse from "@material-ui/core/Collapse";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

import { observable, action, runInAction } from "mobx";
import EditDialog from "./EditDialog";
import ExportDialog from "./ExportDialog";
import ImportDialog from "./ImportDialog";
import ConfirmDialog from "./ConfirmDialog";
import { ExportButton } from "components/Buttons/ExportButton";
import { xor } from "lodash";

// import API from "services/api";

const styles = (theme) => ({
    tableRow: {
        "&$selected, &$selected:hover": {
            backgroundColor: "purple",
        },
    },
    tableCell: {
        "$selected &": {
            color: "yellow",
        },
    },
    selected: {},
});

const DataTable = ({
    autoHeight = true,
    title,
    classes,
    className,
    store,
    extraStore,
    storesConnectingField = "",
    addButton,
    displayHeader = true,
    displaySearchBar = true,
    displayAddButton = true,
    displayImportButton = false,
    displayExportButton = false,
    displayEditButton = true,
    displayEditExtraButton = false,
    extraEditButtons = undefined,
    displayDeleteButton = true,
    displayRecord, // todo.Rename it display - its boolean checkDisplayRecord
    // displayRecordDetails = false,
    displayNavigation = true,
    displayRecordSelectCheckbox = false,
    isSelectedByField = "id",

    recordsSortableEnabled = false, // rename to displayDraggableHandle
    onRecordsSortEnd = null,
    onRecordsSelectionChanged = null,
    updateRecordsList = null,
    getRecordDetails = null,

    selectMultiple = false,
    isDetail = true,

    filter: paramFilter,
    initFilter, //: initFilter,
    //onFilterChange = null,
    onFilterClear = null,
    intlPrefix,
    detailUrl,

    addFields,
    listFields,
    filterFields,
    exportFields,
    listFieldWidth,
    customFieldsRenderer,
    customEditFieldsRenderer,
    customFilterRenderer,
    customButtons,
    compactSize,
    rowHeight,
    recordsPerPage = 25,
    editDialogMaxWidth = "sm",

    onStoreModified,
    orderBy = "ctime desc",
    selectedRecords = [],
    multiSelect = false,
    ignoreInitedValues = false,
    urlVersion = 1,
}) => {
    const { usersStore } = useStores();
    const { formatMessage } = useIntl();

    const history = useHistory();

    // const [order, setOrder] = useState("asc");
    const [orderByColumn, setOrderByColumn] = useState(orderBy);
    const [selected, setSelected] = useState(selectedRecords || []);
    const [page, setPage] = useState(0);
    const [filter, setFilter] = useState({ ...paramFilter, ...initFilter });
    const [rowsPerPage, setRowsPerPage] = useState(recordsPerPage);
    const [error, setError] = useState(undefined);

    const [editDialogOpened, setEditDialogOpened] = useState(false);
    const [editExtraDialogOpened, setEditExtraDialogOpened] = useState(false);
    const [confirmDialogOpened, setConfirmDialogOpened] = useState(false);
    const [importDialogOpened, setImportDialogOpened] = useState(false);
    const [expandedRecordId, setExpandedRecordId] = useState(null);
    const tableWrapperRef = useRef();
    const [maxTableHeight, setMaxTableHeight] = useState(autoHeight ? 0 : "auto");

    useEffect(() => {
        if (tableWrapperRef.current && autoHeight) {
            const top = tableWrapperRef.current.getBoundingClientRect().top;
            setMaxTableHeight(window.innerHeight - top - 60);
        }
    }, [tableWrapperRef.current]);

    if (detailUrl) detailUrl = detailUrl.replace(/\/$/, "");

    if (!listFields) {
        // eslint-disable-next-line no-param-reassign
        if (store.specification && store.specification.columns && store.specification.columns.list) {
            listFields = store.specification.columns.list;
        } else {
            listFields = ["name"];
            displayHeader = false;
        }
    }

    if (!addFields) {
        // eslint-disable-next-line no-param-reassign
        if (store.specification.columns && store.specification.columns.add) {
            addFields = store.specification.columns.add;
        } else {
            addFields = ["name"];
        }
    }

    if (
        store.editingRecord &&
        store.editingRecord.is_imported &&
        addFields.indexOf("customer_id") < 0 &&
        addFields.indexOf("employee_id") < 0
    ) {
        addFields = ["customer_id", "employee_id", ...addFields];
    }
    const reloadList = (inFilter = undefined) => {
        if (store.fetchList) store.fetchList(false, inFilter || filter, orderByColumn, 0, rowsPerPage, isDetail, false, urlVersion);
    };

    useEffect(() => {
        reloadList();
    }, []);

    useEffect(() => {
        if (!isObjectsEqual(filter, paramFilter))
            setFilter((filter) => {
                const newFilter = { ...filter, ...paramFilter, ...initFilter };
                reloadList(newFilter);
                //onFilterChange(newFilter);
                return newFilter;
            });
    }, [paramFilter]);

    /*
    //alert(JSON.stringify(filter));
    useEffect(() => {
        console.log("FILTER", filter);
        if (store.fetchList)
            store.fetchList(false, filter, orderByColumn, 0, rowsPerPage, true);//! !fetchDetail);
    }, [filter]);
*/

    const handleChangePage = (event, page) => {
        setPage(page);

        if (store.fetchList) store.fetchList(false, filter, orderByColumn, page, rowsPerPage, true, false, urlVersion);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value);
        if (store.fetchList) store.fetchList(false, filter, orderByColumn, page, event.target.value, true, false, urlVersion);
    };

    const handleRequestSort = (property) => {
        const orderBy = property;
        const order = "desc";
        let orderByStr = `${orderBy} ${order}`;

        if (orderByColumn === orderByStr) {
            if (order === "desc") orderByStr = `${orderBy} asc`;
            else orderByStr = `${orderBy} desc`;
        }

        setOrderByColumn(orderByStr);

        if (store.fetchList) store.fetchList(false, filter, orderByStr, page, rowsPerPage, true, false, urlVersion);
    };

    const handleSelectAllClick = (event, checked) => {
        if (checked) {
            setSelected(store.list.map((n) => n.id));
            return;
        }
        setSelected([]);
    };

    const handleRowKeyDown = (event, id) => {
        if (keycode(event) === "space") {
            handleSelectRowAdd(event, id);
        }
    };

    const handleSelectRowAdd = (event, id) => {
        let newSelected;

        if (selectMultiple) {
            newSelected = xor(selected, [id]);
        } else {
            newSelected = selected.includes(id) ? [] : [id];
        }
        setSelected(newSelected);

        if (onRecordsSelectionChanged) onRecordsSelectionChanged(newSelected);

        event.stopPropagation();
    };

    const handleSelectRow = (event, id) => {
        setSelected([id]);
    };

    const getFieldValue = (record, field, value) => {
        /*
        if (!value) {
            return (<TableCell >{value}</TableCell>);
        } else
            */
        const style = listFieldWidth && listFieldWidth[field.name] ? { width: listFieldWidth[field.name] } : {};

        if (typeof value === "object" && value !== null && value.avatar && value.name) {
            return (
                <TableCell style={style}>
                    <Link
                        className="data-table-avatar"
                        to={detailUrl ? `${detailUrl}/${record.id}` : null}
                        style={{ marginRight: 10 }}
                    >
                        <Avatar size="40" record={value}>
                            {value.name.substr(0, 2).toUpperCase()}
                        </Avatar>
                        <div>{value.name}</div>
                    </Link>
                </TableCell>
            );
        }
        if (typeof value === "object" && value !== null && value.id && value.name) {
            return (
                <TableCell style={style}>
                    <IntlMessages
                        defaultMessage={value.name}
                        id={`${intlPrefix || store.specification.moduleName}.schema.${field.name}.${value.name}`}
                    />
                </TableCell>
            );
        }
        if (field.type === "json" && typeof value === "object" && value !== null) {
            return (
                <TableCell style={style}>
                    {Object.keys(value).map((key, index) => (
                        <div key={key}>
                            <b>{key}: </b>
                            <span>{value[key]}</span>
                        </div>
                    ))}
                </TableCell>
            );
        }
        if (field.type === "location" && typeof value === "object" && value !== null) {
            return <TableCell style={style}>{value.address}</TableCell>;
        }
        if (field.type === "avatar") {
            return (
                <TableCell className="size-60">
                    <Link to={detailUrl ? `${detailUrl}/${record.id}` : null}>
                        <Avatar size={60} record={record} />
                    </Link>
                </TableCell>
            );
        }
        if (field.type === "email") {
            return (
                <TableCell style={{ ...style, cursor: "pointer" }}>
                    <a
                        target="_new"
                        href={`https://mail.google.com/mail/u/0/?view=cm&fs=1&tf=1&source=mailto&su=&body=Здравствуйте, ${record.name}!%0A%0A%0A--%0A${usersStore.me.name}%0A${usersStore.me.email}&to=${value}`}
                    >
                        {value}
                    </a>
                </TableCell>
            );
        }
        if (field.name === "name") {
            return (
                <TableCell style={{ ...style, cursor: "pointer" }}>
                    {detailUrl ? <Link to={`${detailUrl}/${record.id}`}>{value}</Link> : value}
                </TableCell>
            );
        }
        if (field.name === "ctime" || field.name === "mtime") {
            return <TableCell style={style}>{moment.unix(value).format("YYYY-MM-DD HH:mm")}</TableCell>;
        }
        if (field.type === "date") {
            return <TableCell style={style}>{moment(value).format("YYYY-MM-DD")}</TableCell>;
        }
        if (field.type === "datetime") {
            return <TableCell style={style}>{moment(value).format("YYYY-MM-DD hh:mm")}</TableCell>;
        }
        if (field.type === "time") {
            return <TableCell style={style}>{moment(value).format("hh:mm")}</TableCell>;
        }
        if (field.type === "boolean") {
            return (
                <TableCell style={style}>
                    {value ? (
                        <IntlMessages defaultMessage="Yes" id="form.boolean.yes" />
                    ) : (
                        <IntlMessages defaultMessage="No" id="form.boolean.no" />
                    )}
                </TableCell>
            );
        }
        if (field.type === "url" || (typeof value === "string" && value.match(/^https:\/\//))) {
            return (
                <TableCell style={{ ...style, cursor: "pointer" }}>
                    <a target="_new" href={value}>
                        Открыть
                    </a>
                </TableCell>
            );
        }
        if (
            typeof value === "object" &&
            Array.isArray(value) &&
            typeof field.link === "object" &&
            field.link.type === "many"
        ) {
            return <TableCell style={style}>{value.map((item) => item.name).join(", ")}</TableCell>;
        } else if (typeof value === "object" && value !== null) {
            return <TableCell style={style}>{JSON.stringify(value)}</TableCell>;
        }
        return <TableCell style={style}>{value}</TableCell>;
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    const handleOpenConfrmDeleteDialog = () => {
        setConfirmDialogOpened(true);
    };
    const handleCloseConfirmDeleteDialog = () => {
        setConfirmDialogOpened(false);
    };
    const handleDeleteSelected = () => {
        selected.forEach((id) => {
            store.deleteRecord(id);
        });
        setSelected([]);
        setConfirmDialogOpened(false);
        if (onStoreModified) onStoreModified();
    };

    const handleEditSelected = () => {
        // console.log(store.list.find((item) => item.id === selected[0]));
        store.setEditingRecord(store.list.find((item) => item.id === selected[0]));
        setEditDialogOpened(true);
    };
    const handleEditExtraSelected = async () => {
        const [item] = (await extraStore.fetchList(false, { [storesConnectingField]: selected[0] })) || [];
        if (item) {
            extraStore.setEditingRecord(item);
            setEditExtraDialogOpened(true);
        }
    };

    const handleDialogOpen = () => {
        store.setEditingRecord({});
        setEditDialogOpened(true);
    };

    const handleDialogClose = () => {
        setEditDialogOpened(false);
    };
    const handleExtraDialogClose = () => {
        setEditExtraDialogOpened(false);
    };

    const handleOpenImportDialog = () => {
        setImportDialogOpened(true);
    };
    const handleCloseImportDialog = () => {
        setImportDialogOpened(false);
    };
    const handleImportDone = () => {
        setImportDialogOpened(false);

        if (store.fetchList) store.fetchList(false, paramFilter, orderByColumn, page, rowsPerPage, true, false, urlVersion);
    };

    const handleDialogSave = async (record) => {
        const saveRecord = { ...record, ...filter };
        delete saveRecord["$nodx_search"];
        delete saveRecord.mtime;
        delete saveRecord.ctime;
        delete saveRecord.id;
        const item = record.id ? await store.updateRecord(record.id, saveRecord) : await store.addRecord(saveRecord);
        if (!store.addingError) {
            if (detailUrl) history.push(`${detailUrl}/${item.id}`);
            setEditDialogOpened(false);
        }

        if (onStoreModified) {
            onStoreModified();
        }
    };
    const handleExtraDialogSave = async (record) => {
        const saveRecord = { ...record, ...filter };
        delete saveRecord["$nodx_search"];
        delete saveRecord.mtime;
        delete saveRecord.ctime;
        delete saveRecord.id;
        const item = record.id ? await store.updateRecord(record.id, saveRecord) : await store.addRecord(saveRecord);
        if (!store.addingError) {
            if (detailUrl) history.push(`${detailUrl}/${item.id}`);
            setEditDialogOpened(false);
        }

        if (onStoreModified) {
            onStoreModified();
        }
    };
    const handleFilterList = async (field, value, event) => {
        setSelected([]);

        const newFilter = { ...filter };
        const nestedNames = field.name ? field.name.split(".") : [];
        if ((value === null || value === "") && filter[field.name] !== undefined) {
            if (nestedNames.length > 1) {
                delete newFilter[nestedNames[0]][nestedNames[1]];
            } else {
                delete newFilter[field.name];
            }
        } else {
            if (nestedNames.length > 1) {
                if (!newFilter[nestedNames[0]]) {
                    newFilter[nestedNames[0]] = {};
                }
                newFilter[nestedNames[0]][nestedNames[1]] = value;
            } else {
                newFilter[field.name] = value;
            }
        }
        //onFilterChange(newFilter);

        if (store.fetchList) await store.fetchList(false, newFilter, orderByColumn, page, rowsPerPage, true, false, urlVersion);
        setFilter(newFilter);
    };

    const handleFilterClear = async (event) => {
        setSelected([]);

        setFilter(paramFilter);
        //onFilterChange(paramFilter);
        if (onFilterClear) onFilterClear();

        if (store.fetchList) store.fetchList(false, paramFilter, orderByColumn, page, rowsPerPage, true, false, urlVersion);
    };

    /* records sortable */
    const DragHandle = SortableHandle(({ style }) => <span style={{ ...style, ...{ cursor: "move" } }}>::::</span>);

    const TableBodySortable = SortableContainer(({ children }) => <TableBody>{children}</TableBody>);

    // Компонент строки таблицы с оберткой в sortable элемент
    const TableRowSortable = SortableElement(({ children, ...other }) => <TableRow {...other}>{children}</TableRow>);

    const onRecordsSortEndHandle = ({ oldIndex, newIndex }) => {
        const list = getRecordsList();
        if (onRecordsSortEnd) onRecordsSortEnd(list[oldIndex].id, list[newIndex].id);
        else store.resortList(list[oldIndex].id, list[newIndex].id);
    };

    // Строка необходима для того чтобы наш кастомный боди воспринимался как TableBody и в этом случае ошибки не будет
    TableBodySortable.muiName = "TableBody";

    /*
    const Row = SortableElement(({ data, ...other }) => {
        return (
            <TableRow {...other}>
                {other.children[0]}
                <TableRowColumn style={{ width: '5%' }}>
                    <DragHandle />
                </TableRowColumn>
                <TableRowColumn>
                    {data.id}
                </TableRowColumn>
                <TableRowColumn>
                    {data.name}
                </TableRowColumn>
                <TableRowColumn>
                    {data.status}
                </TableRowColumn>
            </TableRow>
        )
    })
    */

    const getRecordsList = () => {
        if (!store.list) return [];

        const list = store.list.slice(0 * rowsPerPage, 0 * rowsPerPage + rowsPerPage);

        if (updateRecordsList) {
            return updateRecordsList(list);
        }

        return list;
    };

    return useObserver(() => {
        const records = getRecordsList();

        if (!store.specification) {
            alert("Specification not found!");
            return (
                <div>
                    <CircularProgress />
                </div>
            );
        }


        const extraEditButtonComponents =
            extraEditButtons && selected && selected.length === 1 ? extraEditButtons.map(extraButton => (
                (!extraButton.condition || (extraButton.condition && extraButton.condition(store.list[0])))
                    ? compactSize
                        ? (
                            <Fab
                                className="jr-fab-btn bg-sec text-white  bg-primary jr-btn-fab-xs mb-3"
                                onClick={() => extraButton.onClick(selected[0])}
                            >
                                {extraButton.icon}
                            </Fab>
                        ) : (
                            <Button
                                variant="contained"
                                className="ml-3 jr-btn text-white bg-blue jr-btn-lg"
                                onClick={() => extraButton.onClick(selected[0])}
                            >
                                {extraButton.icon}
                                <span>{extraButton.name}</span>
                            </Button>
                        )
                : null

            )) : null;
        return (
            <div>
                <EditDialog
                    open={editDialogOpened}
                    onClose={handleDialogClose}
                    onSave={handleDialogSave}
                    intlPrefix={intlPrefix}
                    store={store}
                    fields={addFields}
                    error={error}
                    maxWidth={editDialogMaxWidth}
                    customEditFieldsRenderer={customEditFieldsRenderer}
                    ignoreInitedValues={ignoreInitedValues}
                />
                {extraStore && (
                    <EditDialog
                        open={editExtraDialogOpened}
                        onClose={handleExtraDialogClose}
                        onSave={handleExtraDialogSave}
                        intlPrefix={intlPrefix}
                        store={extraStore}
                        fields={extraStore?.specification?.columns?.list || []}
                        error={error}
                        maxWidth={editDialogMaxWidth}
                        ignoreInitedValues={ignoreInitedValues}
                    />
                )}
                <ImportDialog
                    open={importDialogOpened}
                    /*                    onClose={handleCloseImportDialog}*/
                    onClose={handleImportDone}
                    store={store}
                    fileType="xls"
                />
                <ConfirmDialog
                    open={confirmDialogOpened}
                    onClose={handleCloseConfirmDeleteDialog}
                    onAgree={handleDeleteSelected}
                    title={<IntlMessages id="dialog.confirm_delete.title" />}
                    description={<IntlMessages id="dialog.confirm_delete.description" />}
                />

                <div className="row">
                    <CardBox styleName="col-12" cardStyle=" p-0">
                        <Paper>
                            {compactSize ? (
                                <div
                                    className="bg-gradient-primary  jr-card jr-card-widget"
                                    style={{ margin: 0, padding: "20px 20px 30px 20px" }}
                                >
                                    <div className="card-title" style={{ margin: 0 }}>
                                        <div style={{ float: "left" }}>
                                            {title || (
                                                <IntlMessages
                                                    id={`${intlPrefix || store.specification.moduleName}.list.title`}
                                                />
                                            )}
                                        </div>
                                        <div style={{ float: "right", marginTop: -8 }}>
                                            {displayDeleteButton && selected && selected.length ? (
                                                <Fab
                                                    className="jr-fab-btn bg-sec text-white  bg-deep-orange jr-btn-fab-xs mb-3"
                                                    onClick={handleOpenConfrmDeleteDialog}
                                                >
                                                    <i className="zmdi zmdi-delete" />
                                                </Fab>
                                            ) : (
                                                ""
                                            )}

                                            {displayEditButton && selected && selected.length === 1 ? (
                                                <Fab
                                                    className="jr-fab-btn bg-sec text-white  bg-primary jr-btn-fab-xs mb-3"
                                                    onClick={handleEditSelected}
                                                >
                                                    <i className="zmdi zmdi-edit" />
                                                </Fab>
                                            ) : (
                                                ""
                                            )}
                                            {displayEditExtraButton && selected && selected.length === 1 ? (
                                                <Fab
                                                    className="jr-fab-btn bg-sec text-white  bg-primary jr-btn-fab-xs mb-3"
                                                    onClick={handleEditExtraSelected}
                                                >
                                                    <i className="zmdi zmdi-edit" />
                                                </Fab>
                                            ) : (
                                                ""
                                            )}
                                            {extraEditButtonComponents}

                                            {displayAddButton
                                                ? addButton || (
                                                      <Fab
                                                          className="jr-fab-btn bg-sec text-white bg-primary jr-btn-fab-xs mb-3"
                                                          onClick={handleDialogOpen}
                                                      >
                                                          <i className="zmdi zmdi-plus" />
                                                      </Fab>
                                                  )
                                                : ""}

                                            {customButtons && customButtons.length ? (
                                                <div>
                                                    {customButtons.map((item) => (
                                                        <Fab
                                                            className={`jr-fab-btn bg-sec jr-btn-fab-xs mb-3 ${item.className} `}
                                                            onClick={() => item.handler(selected)}
                                                        >
                                                            <i className={`zmdi ${item.icon}`} />
                                                        </Fab>
                                                    ))}
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    <div className="card-header d-flex" style={{ "flex-wrap": "wrap" }}>
                                        <div className="mr-auto" style={{ paddingTop: 5 }}>
                                            <h3 className="card-heading d-inline-block mb-0">
                                                {title || (
                                                    <IntlMessages
                                                        id={`${intlPrefix || store.specification.moduleName}.list.title`}
                                                    />
                                                )}
                                            </h3>
                                        </div>

                                        {filterFields &&
                                            filterFields.map((name) => {
                                                if (name && name.indexOf(".") > 0) {
                                                    const nestedNames = name.split(".");
                                                    const column = store.specification.fields.find(
                                                        (item) => item.name === nestedNames[0],
                                                    );
                                                    if (column) {
                                                        return (
                                                            <div
                                                                className="ml-3 filter-field filter-field-"
                                                                style={{ marginTop: -6, minWidth: "250px" }}
                                                            >
                                                                <EditField
                                                                    store={store}
                                                                    nestedField={name}
                                                                    field={{ ...column, name }}
                                                                    value={
                                                                        store.filter[nestedNames[0]] &&
                                                                        store.filter[nestedNames[0]][nestedNames[1]]
                                                                    }
                                                                    onChange={handleFilterList}
                                                                    disabled={store?.isFetchingListInProgress}
                                                                />
                                                            </div>
                                                        );
                                                    }
                                                }
                                                let column = store.specification.fields.find(
                                                    (item) => item.name === name,
                                                );
                                                if (!column) {
                                                    column = {
                                                        name,
                                                        minWidth: "250px",
                                                        type: "string"
                                                    }
                                                }
                                                if (column) {
                                                    return (
                                                        <div
                                                            className="ml-3 filter-field filter-field-"
                                                            style={{
                                                                marginTop: -6,
                                                                width: column.name === "id" ? "100px" : "auto",
                                                                ...column.minWidth ? {   minWidth: column.minWidth } : {},

                                                            }}
                                                        >
                                                            <EditField
                                                                store={store}
                                                                field={column}
                                                                value={store.filter[name]}
                                                                onChange={handleFilterList}
                                                                disabled={store?.isFetchingListInProgress}
                                                            />
                                                        </div>
                                                    );
                                                }
                                            })}

                                        {displaySearchBar ? (
                                            <SearchBox
                                                styleName="d-none d-sm-block ml-3"
                                                value={
                                                    store.filter && store.filter.$nodx_search
                                                        ? store.filter.$nodx_search
                                                        : ""
                                                }
                                                placeholder={formatMessage({ id: "search.placeholder" })}
                                                name="$nodx_search"
                                                onChange={(event) => {
                                                    handleFilterList({ name: "$nodx_search" }, event.target.value);
                                                }}
                                            />
                                        ) : (
                                            ""
                                        )}

                                        {!isObjectsEqual(filter, paramFilter) ? (
                                            <Button
                                                variant="contained"
                                                className="ml-3 jr-btn text-white bg-blue jr-btn-lg"
                                                onClick={handleFilterClear}
                                            >
                                                <span>Очистить фильтр</span>
                                            </Button>
                                        ) : (
                                            ""
                                        )}

                                        {displayImportButton ? (
                                            <Button
                                                variant="contained"
                                                className="ml-3 jr-btn  jr-btn-lg"
                                                onClick={handleOpenImportDialog}
                                            >
                                                <i className="zmdi zmdi-upload" />
                                                <span>
                                                    <IntlMessages id="button.import" />
                                                </span>
                                            </Button>
                                        ) : (
                                            ""
                                        )}

                                        {displayExportButton && exportFields ? (
                                            <ExportButton
                                                store={store}
                                                fields={exportFields}
                                                filter={filter}
                                                disabled={
                                                    store?.isFetchingListInProgress || store?.isFetchingExportInProgress
                                                }
                                            />
                                        ) : (
                                            ""
                                        )}

                                        {displayDeleteButton && selected && selected.length ? (
                                            <Button
                                                variant="contained"
                                                className="ml-3 jr-btn jr-btn-lg"
                                                onClick={handleOpenConfrmDeleteDialog}
                                            >
                                                <i className="zmdi zmdi-collection-plus" />
                                                <span>
                                                    <IntlMessages id="button.delete" />
                                                </span>
                                            </Button>
                                        ) : (
                                            ""
                                        )}

                                        {displayEditButton && selected && selected.length === 1 ? (
                                            <Button
                                                variant="contained"
                                                className="ml-3 jr-btn text-white bg-blue jr-btn-lg"
                                                onClick={handleEditSelected}
                                            >
                                                <i className="zmdi zmdi-collection-plus" />
                                                <span>
                                                    <IntlMessages id="button.edit" />
                                                </span>
                                            </Button>
                                        ) : (
                                            ""
                                        )}
                                        {displayEditExtraButton && selected && selected.length === 1 ? (
                                            <Button
                                                variant="contained"
                                                className="ml-3 jr-btn text-white bg-blue jr-btn-lg"
                                                onClick={handleEditExtraSelected}
                                            >
                                                <i className="zmdi zmdi-collection-plus" />
                                                <span>
                                                    <IntlMessages id="button.edit_price" />
                                                </span>
                                            </Button>
                                        ) : (
                                            ""
                                        )}
                                        {extraEditButtonComponents}

                                        {displayAddButton ? (
                                            <Button
                                                variant="contained"
                                                className="ml-3 jr-btn text-white bg-blue jr-btn-lg"
                                                onClick={handleDialogOpen}
                                            >
                                                <i className="zmdi zmdi-collection-plus" />
                                                <span>
                                                    <IntlMessages id="button.add" />
                                                </span>
                                            </Button>
                                        ) : (
                                            ""
                                        )}

                                        {customButtons && customButtons.length ? (
                                            <>
                                                {customButtons.map((item) => {
                                                    if (
                                                        item.displayIfSelected === undefined ||
                                                        (item.displayIfSelected && selected && selected.length === 1)
                                                    ) {
                                                        return (
                                                            <Button
                                                                variant="contained"
                                                                className={`ml-3 jr-btn jr-btn-lg ${item.className}`}
                                                                onClick={() => item.handler(selected)}
                                                                {...(item?.attributes || {})}
                                                            >
                                                                <i className={`zmdi ${item.icon}`} />
                                                                <span>
                                                                    <IntlMessages id={item.title} />
                                                                </span>
                                                            </Button>
                                                        );
                                                    }
                                                    return null;
                                                })}
                                            </>
                                        ) : (
                                            ""
                                        )}
                                    </div>

                                    {/*
                                <AppBar className={`app-main-header jr-border-radius ${className || ""}`} position="static">
                                    <Toolbar>
                                        <IconButton className="jr-menu-icon" aria-label="Menu">
                                            <span className="menu-icon" />
                                        </IconButton>

                                        <h4 className="mb-0 mr-auto text-white"><IntlMessages id={`${intlPrefix || store.specification.moduleName}.list.title`} /></h4>

                                        {filterFields && filterFields.map((name) => {
                                            const column = store.specification.fields.find((item) => item.name === name);
                                            if (column) {
                                                return (
                                                    <div className="ml-3 filter-field" style={{marginTop:-6}}>
                                                        <EditField
                                                            store={store}
                                                            field={column}
                                                            value={store.filter[name]}
                                                            onChange={handleFilterList}
                                                        />
                                                    </div>
                                                );
                                            }
                                        })}

                                        {displaySearchBar ? (
                                            <SearchBox
                                                styleName="d-none d-sm-block ml-3"
                                                value={store.filter && store.filter.$nodx_search ? store.filter.$nodx_search : ""}
                                                placeholder={formatMessage({ id: "search.placeholder" })}
                                                name="$nodx_search"
                                                onChange={(event)=>{handleFilterList({name:"$nodx_search"}, event.target.value)}}
                                            />
                                        ) : ""}

                                        {filter !== paramFilter ? (

                                            <Button variant="contained" className="ml-3 jr-btn text-white bg-blue jr-btn-lg" onClick={handleFilterClear}>
                                                <span>Очистить фильтр</span>
                                            </Button>
                                        ) : ""}

                                        {displayImportButton ? (

                                            <Button variant="contained" className="ml-3 jr-btn  jr-btn-lg" onClick={handleImportOpen}>
                                                <i className="zmdi zmdi-upload" />
                                                <span><IntlMessages id="button.import" /></span>
                                            </Button>
                                        ) : ""}

                                        {displayExportButton ? (

                                            <Button variant="contained" className="ml-3 jr-btn jr-btn-lg" onClick={handleExportOpen}>
                                                <i className="zmdi zmdi-download" />
                                                <span><IntlMessages id="button.export" /></span>
                                            </Button>
                                        ) : ""}

                                        {displayDeleteButton && selected && selected.length ? (
                                            <Button variant="contained" className="ml-3 jr-btn text-white bg-deep-orange jr-btn-lg" onClick={handleOpenConfrmDeleteDialog}>
                                                <i className="zmdi zmdi-collection-plus" />
                                                <span><IntlMessages id="button.delete" /></span>
                                            </Button>
                                        ) : ""}

                                        {displayEditButton && selected && selected.length === 1 ? (
                                            <Button variant="contained" className="ml-3 jr-btn text-white bg-blue jr-btn-lg" onClick={handleEditSelected}>
                                                <i className="zmdi zmdi-collection-plus" />
                                                <span><IntlMessages id="button.edit" /></span>
                                            </Button>
                                        ) : ""}

                                        {displayAddButton ? (

                                            <Button variant="contained" className="ml-3 jr-btn text-white bg-blue jr-btn-lg" onClick={handleDialogOpen}>
                                                <i className="zmdi zmdi-collection-plus" />
                                                <span><IntlMessages id="button.add" /></span>
                                            </Button>
                                        ) : ""}

                                        {customButtons && customButtons.length ? (
                                            <div>
                                                {customButtons.map((item) => (
                                                    <Button variant="contained" className={`ml-3 jr-btn jr-btn-lg ${item.className}`} onClick={() => item.handler(selected)}>
                                                        <i className={`zmdi zmdi-collection-plus ${item.icon}`} />
                                                        <span><IntlMessages id={item.title} /></span>
                                                    </Button>
                                                ))}
                                            </div>
                                        ) : ""}
                                    </Toolbar>
                                </AppBar>
                                */}
                                </div>
                            )}

                            {store.isFetchingListInProgress && (
                                <div style={{ width: "100%" }}>
                                    <CircularProgress />
                                </div>
                            )}
                            {store.fetchingListError && <Error>{store.fetchingListError}</Error>}
                            {!store.isFetchingListInProgress && !store.fetchingListError && (
                                <div>
                                    <div ref={tableWrapperRef} style={{ width: "100%" }}>
                                        <TableContainer style={{ maxHeight: maxTableHeight }}>
                                            <Table stickyHeader>
                                                {displayHeader && (
                                                    <TableHead style={{ backgroundColor: "#f5f5f5" }}>
                                                        <TableRow
                                                            classes={{ selected: classes.selected }}
                                                            className={classes.tableRow}
                                                            style={{ height: compactSize ? 30 : 60 }}
                                                        >
                                                            {recordsSortableEnabled ? <TableCell /> : null}

                                                            {
                                                                /*displayDeleteButton ||*/ displayRecordSelectCheckbox && (
                                                                    <TableCell padding="checkbox">
                                                                        <Checkbox
                                                                            color="primary"
                                                                            indeterminate={
                                                                                records &&
                                                                                selected.length > 0 &&
                                                                                selected.length < records.length
                                                                            }
                                                                            checked={
                                                                                records &&
                                                                                selected.length === records.length
                                                                            }
                                                                            onChange={handleSelectAllClick}
                                                                        />
                                                                    </TableCell>
                                                                )
                                                            }

                                                            {getRecordDetails ? <TableCell padding="checkbox" /> : null}

                                                            {listFields.map((name) => {
                                                                const column = store.specification.fields.find(
                                                                    (item) => item.name === name,
                                                                );
                                                                if (!column || (!column.sortable && column.link)) {
                                                                    return (
                                                                        <TableCell>
                                                                            <IntlMessages
                                                                                id={`${intlPrefix || store.specification.moduleName}.schema.${name}`}
                                                                            />
                                                                        </TableCell>
                                                                    );
                                                                }
                                                                return (
                                                                    <TableCell
                                                                        key={column.name}
                                                                        className={classes.tableCell}
                                                                    >
                                                                        <Tooltip
                                                                            title="Sort"
                                                                            placement={
                                                                                column.numeric
                                                                                    ? "bottom-end"
                                                                                    : "bottom-start"
                                                                            }
                                                                            enterDelay={300}
                                                                        >
                                                                            <TableSortLabel
                                                                                active={
                                                                                    orderByColumn.replace(
                                                                                        / .*/g,
                                                                                        "",
                                                                                    ) === column.name
                                                                                }
                                                                                direction={
                                                                                    orderByColumn.replace(
                                                                                        / .*/g,
                                                                                        "",
                                                                                    ) === column.name
                                                                                        ? orderByColumn.replace(
                                                                                              /.* /g,
                                                                                              "",
                                                                                          ) === "desc"
                                                                                            ? "desc"
                                                                                            : "asc"
                                                                                        : "asc"
                                                                                }
                                                                                onClick={() =>
                                                                                    handleRequestSort(column.name)
                                                                                }
                                                                            >
                                                                                <IntlMessages
                                                                                    id={`${intlPrefix || store.specification.moduleName}.schema.${column.name}`}
                                                                                />
                                                                            </TableSortLabel>
                                                                        </Tooltip>
                                                                    </TableCell>
                                                                );
                                                            })}
                                                        </TableRow>
                                                    </TableHead>
                                                )}

                                                <TableBodySortable onSortEnd={onRecordsSortEndHandle} useDragHandle>
                                                    {(!records || !records.length) && (
                                                        <TableRow
                                                            hover
                                                            role="checkbox"
                                                            tabIndex={-1}
                                                            style={{ height: compactSize ? 40 : rowHeight || 80 }}
                                                        >
                                                            <TableCell colSpan={100}>
                                                                <IntlMessages id="table.listIsEmpty" />
                                                            </TableCell>
                                                        </TableRow>
                                                    )}

                                                    {records.map((record, index) => {
                                                        // TODO: rename n!!!!!!!!!!!!!!!!!!
                                                        // console.log(">>>>>", record);
                                                        if (record === null) return;
                                                        if (displayRecord && !displayRecord(record)) return;

                                                        const isSelect = isSelected(record[isSelectedByField]);
                                                        return (
                                                            <React.Fragment key={record.id}>
                                                                <TableRowSortable
                                                                    hover
                                                                    onClick={(event) =>
                                                                        handleSelectRowAdd(
                                                                            event,
                                                                            record[isSelectedByField],
                                                                        )
                                                                    }
                                                                    onKeyDown={(event) =>
                                                                        handleRowKeyDown(
                                                                            event,
                                                                            record[isSelectedByField],
                                                                        )
                                                                    }
                                                                    role="checkbox"
                                                                    aria-checked={isSelect}
                                                                    tabIndex={-1}
                                                                    key={record.id}
                                                                    index={index}
                                                                    selected={isSelect}
                                                                    style={{
                                                                        height: compactSize ? 40 : rowHeight || 80,
                                                                    }}
                                                                >
                                                                    {recordsSortableEnabled ? (
                                                                        <TableCell padding="checkbox">
                                                                            <DragHandle />
                                                                        </TableCell>
                                                                    ) : null}

                                                                    {
                                                                        /*displayDeleteButton ||*/ displayRecordSelectCheckbox && (
                                                                            <TableCell
                                                                                padding="checkbox"
                                                                                onClick={(event) =>
                                                                                    handleSelectRowAdd(
                                                                                        event,
                                                                                        record[isSelectedByField],
                                                                                    )
                                                                                }
                                                                            >
                                                                                <Checkbox
                                                                                    color="primary"
                                                                                    checked={isSelect}
                                                                                />
                                                                            </TableCell>
                                                                        )
                                                                    }

                                                                    {getRecordDetails ? (
                                                                        <TableCell padding="checkbox">
                                                                            <IconButton
                                                                                aria-label="expand row"
                                                                                size="small"
                                                                                onClick={() => {
                                                                                    setExpandedRecordId(
                                                                                        expandedRecordId !== record.id
                                                                                            ? record.id
                                                                                            : null,
                                                                                    );
                                                                                }}
                                                                            >
                                                                                {expandedRecordId === record.id ? (
                                                                                    <KeyboardArrowUpIcon />
                                                                                ) : (
                                                                                    <KeyboardArrowDownIcon />
                                                                                )}
                                                                            </IconButton>
                                                                        </TableCell>
                                                                    ) : null}

                                                                    {listFields.map((name) => {
                                                                        const value = record[name];

                                                                        let field = store.specification.fields.find(
                                                                            (item) => item.name === name,
                                                                        );
                                                                        if (!field && name === "ctime")
                                                                            field = { type: "number", name: "ctime" };
                                                                        else if (!field && name === "mtime")
                                                                            field = { type: "number", name: "mtime" };
                                                                        else if (!field)
                                                                            field = {
                                                                                type: "string",
                                                                                name: `${name}_id`,
                                                                            };

                                                                        if (
                                                                            customFieldsRenderer &&
                                                                            customFieldsRenderer[name]
                                                                        ) {
                                                                            try {
                                                                                return (
                                                                                    <TableCell>
                                                                                        {customFieldsRenderer[name](
                                                                                            record,
                                                                                            field,
                                                                                            value,
                                                                                        )}
                                                                                    </TableCell>
                                                                                );
                                                                            } catch (e) {
                                                                                //alert(`Error rendering field: ${name}: ${e}`);
                                                                                console.error(
                                                                                    `Error rendering field: ${name}`,
                                                                                );
                                                                                console.error(e);
                                                                            }
                                                                        }
                                                                        return getFieldValue(record, field, value);
                                                                    })}
                                                                </TableRowSortable>
                                                                {getRecordDetails && expandedRecordId === record.id ? (
                                                                    <TableRow key={record.id}>
                                                                        <TableCell
                                                                            style={{ paddingBottom: 0, paddingTop: 0 }}
                                                                            colSpan={6}
                                                                        >
                                                                            <Collapse
                                                                                in={expandedRecordId === record.id}
                                                                                timeout="auto"
                                                                                unmountOnExit
                                                                            >
                                                                                {getRecordDetails(record)}
                                                                            </Collapse>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ) : null}
                                                            </React.Fragment>
                                                        );
                                                    })}
                                                </TableBodySortable>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                    {records && displayNavigation && store.total > records.length ? (
                                        <div className="d-flex justify-content-end">
                                            <TableFooter>
                                                <TableRow>
                                                    <TablePagination
                                                        count={store.total /* records ? records.length : 0 */}
                                                        rowsPerPage={rowsPerPage}
                                                        page={page}
                                                        onChangePage={handleChangePage}
                                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                                    />
                                                </TableRow>
                                            </TableFooter>
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            )}
                        </Paper>
                    </CardBox>
                </div>
            </div>
        );
    });
};

export default withStyles(styles)(DataTable);
