import { AxiosInstance } from "axios";
import CommonCRUD from "modules/CommonCRUD";

class Analytics extends CommonCRUD {
    async getAdminStats(dateStart, dateEnd) {
        return await this.api.fetch({
            method: "get",
            url: `/${this.schema}/admin/?date_start=${dateStart}&date_end=${dateEnd}`,
        });
    }

    async getManagerStats(dateStart, dateEnd) {
        return await this.api.fetch({
            method: "get",
            url: `/${this.schema}/manager/?date_start=${dateStart}&date_end=${dateEnd}`,
        });
    }

    async getCustomerStats(customerId, dateStart, dateEnd) {
        return await this.api.fetch({
            method: "get",
            url: `/${this.schema}/customer/?customer_id=${customerId}&date_start=${dateStart}&date_end=${dateEnd}`,
        });
    }
}

export default Analytics;
