import React, { useState, useRef, useMemo, useEffect } from "react";
import { useObserver } from "mobx-react-lite";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { FormControl, InputLabel } from "@material-ui/core";
import IntlMessages from "util/IntlMessages";
import FileBase64 from "react-file-base64";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { KeyboardDateTimePicker, KeyboardTimePicker, KeyboardDatePicker } from "@material-ui/pickers";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputMask from "react-input-mask";
import { useIntl } from "react-intl";
import FormLabel from "@material-ui/core/FormLabel";
import moment from "moment";
// import CKEditor from 'react-ckeditor-component';
import CKEditor from "ckeditor4-react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import Avatar from "@material-ui/core/Avatar";
import Checkbox from "@material-ui/core/Checkbox";
import { useStores } from "modules/stores";
import GoogleMapAutoComplete from "./map/GoogleMapAutoComplete";
import GoogleMapSelectPolygon from "./map/GoogleMapSelectPolygon";
import { isEmailValid } from "util/regExp/isEmailValid";
import { useDebouncedEffect } from "util/useDebouncedEffect";

const OPTION_NAME_SPLITTER = "___";

const EditField = ({
    store,
    filter = {},
    id,
    autoFocus = false,
    field,
    error,
    value,
    intlPrefix,
    multiple,
    className,
    onChange,
    onEnter,
    label,
    variant = "outlined",
    row = true,
    addedOption = null,
    disableOptionAvatars = false,
    renderOptionsEmail = false,
    disabled = false,
}) => {
    // TODO. too bad!!!
    const stores = useStores();

    const { formatMessage } = useIntl();
    const [defaultValue, setDefaultValue] = useState(addedOption);
    const [val, setVal] = useState(value);
    useDebouncedEffect(
        () => {
            console.log("useDebouncedEffect ->", val, value);
            if (val !== value && onChange) {
                onChange(field, val);
            }
        },
        [val],
        1000,
    );
    const fetchLinkData = async (filterParams, fieldName, limit = 15, uid) => {
        if (field && field.link && field.link.straight) {
            await store.fetchList(false, filterParams, undefined, undefined, limit);
        } else {
            await store.fetchLinkedList(fieldName, filterParams, uid, fieldName === "class_id" && limit);
        }
    };
    //load list if value is exists
    useEffect(() => {
        (async () => {
            if (
                field &&
                (field.type === "number" || field.type === "string") &&
                field.link &&
                store &&
                (value || field.link.straight)
            ) {
                const uid = field.name + "_id"; // + id;
                //
                //alert(id + ":" + field.name +":" + value);
                //if (!Object.keys(filter).length || (Object.keys(filter).length === 1 && Object.keys(filter)[0] === "id") ) {
                //if ( value ) {
                //  filter["id"] = value;
                //}
                //}
                //alert(JSON.stringify(filter));
                await fetchLinkData({ ...filter, ...(value ? { id: value } : {}) }, field.name, 15, uid);
                const list = field.link.straight ? store.link : store.linkedList[uid];
                if (list && list.length) {
                    const [newValue] = list.filter((item) => item.id === value);
                    if (!addedOption || (addedOption && newValue)) setDefaultValue(newValue);
                    if (newValue) {
                        const ev = {
                            name: field.name,
                            value: newValue.id,
                            target: { name: field.name, value: newValue.id },
                        };
                        onChange(field, value, ev, true);
                    }
                }
            }
        })();
    }, []);

    const [errorText, setErrorText] = useState(error);
    const [open, setOpen] = useState(false);
    const options = useMemo(() => {
        const uid = field.name + "_id"; // + id;
        return field.link && field.link.straight ? store.list : store.linkedList && store.linkedList[uid];
    }, [field.name]);

    const [autocompleteId, setAutocompleteId] = useState(null);
    // TODO: Callback
    const getFiles = (field, files) => {
        files.base64 = files.base64.replace(/^.*base64,/g, "");
        onChange(field, files);
    };

    const capitalize = (s) => {
        if (typeof s !== "string") return "";
        return s.charAt(0).toUpperCase() + s.slice(1);
    };

    const onChangeCheckboxHandle = (event) => {
        if (event.target.checked) {
            onChange(field, 1, event);
        } else {
            onChange(field, 0, event);
        }

        /*
        const value = event.target.value;
        console.log(event, "@@@@@@@");
        //if
        //event.target.value = "sdf";
        onChange(event);
        */
    };

    const onChangeHandle = (event) => {
        onChange(field, event.target.value, event);
        /*
        const value = event.target.value;
        console.log(event, "@@@@@@@");
        //if
        //event.target.value = "sdf";
        onChange(event);
        */
    };

    const onMapChangeHandle = (place) => {
        console.log(place);
        let address = "";
        const detail = {};
        place.address_components.reverse().forEach((item) => {
            detail[item.types[0]] = item.long_name;
        });

        if (place.name || place.formatted_address) {
            address = place.name || place.formatted_address;
        } else {
            place.address_components.reverse().forEach((item) => {
                if (
                    //item.types.includes("country") ||
                    //item.types.includes("administrative_area_level_1") ||
                    //item.types.includes("administrative_area_level_2") ||
                    //  item.types.includes("administrative_area_level_3") ||
                    item.types.includes("locality") ||
                    item.types.includes("route") ||
                    item.types.includes("premise") ||
                    item.types.includes("establishment") ||
                    item.types.includes("street_number")
                ) {
                    if (address !== "") address += ", ";
                    address += item.long_name;
                }
            });

            if (address === "") {
                place.address_components.reverse().forEach((item) => {
                    if (item.types.includes("administrative_area_level_2")) {
                        address += item.long_name;
                    }
                });
            }
        }

        console.log("TYPE", typeof place.geometry.location.lat);
        const mapValue = {
            address,
            detail,
            lat:
                typeof place.geometry.location.lat === "function"
                    ? place.geometry.location.lat()
                    : place.geometry.location.lat,
            lng:
                typeof place.geometry.location.lng === "function"
                    ? place.geometry.location.lng()
                    : place.geometry.location.lng,
        };

        onChange(field, mapValue);
        /*
        const value = event.target.value;
        console.log(event, "@@@@@@@");
        //if
        //event.target.value = "sdf";
        onChange(event);
        */
    };

    const onMapPolygonChangeHandle = (polygon) => {
        console.log(polygon);
        onChange(field, polygon);
    };

    const onEditorChangeHandle = (event, name) => {
        console.log(event.editor.getData());
        // alert("editor currently cleared old form value");
        // return;
        // if
        // event.target.value = "sdf";

        onChange(field, event.editor.getData(), event);
    };

    const onBlurHandle = (event) => {
        const { value } = event.target;
        if (field.type === "email") {
            if (!isEmailValid(value)) {
                setErrorText(formatMessage({ id: "form.invalidEmail" }));
            } else {
                setErrorText(null);
            }
        }

        if (field.type === "password") {
            if (value.length < 6) {
                setErrorText(formatMessage({ id: "form.invalidPassword" }));
            } else {
                setErrorText(null);
            }
        }
    };
    const onKeyDownHandle = (event) => {
        if (onEnter && event.keyCode === 13) {
            onEnter();
        }
    };

    const useAutocompleteInputClear = (watch, checkClear) => {
        const elmRef = useRef(null);
        useMemo(() => {
            if (!elmRef || !elmRef.current) return;

            if (!checkClear || typeof checkClear !== "function") return;

            const button = elmRef.current.querySelector("button");
            if (checkClear(watch) && button) {
                button.click();
            }
        }, [watch]);

        return elmRef;
    };
    const elmRef = useAutocompleteInputClear(value, (v) => !v || !v.id);

    useEffect(() => {
        const uid = `${field.name}_id`;
        const list = field.link && field.link.straight ? store.list : store.linkedList && store.linkedList[uid];
        if (list) {
            const [newValue] = list.filter((item) => item.id === value);
            if (!defaultValue || (newValue && defaultValue.id !== newValue.id)) {
                setDefaultValue(newValue);
            }
        }
    }, [value, field.link && field.link.straight && store.list, defaultValue]);

    const [prevSearchValue, setPrevSearchValue] = useState("");

    return useObserver(() => {
        const required = field.required ? <span style={{ color: "#610000" }}> *</span> : "";
        if (field.type === "avatar" || field.type === "file") {
            // TODO
            return (
                <FormControl fullWidth className="input-field-file" key={field.name}>
                    <Button variant={variant} component="label" fullWidth>
                        {value && value.name ? (
                            value.name
                        ) : (
                            <IntlMessages
                                defaultMessage={capitalize(field.name)}
                                id={`${intlPrefix || (store.specification ? store.specification.moduleName : "")}.schema.${field.name}`}
                            />
                        )}
                        {required}
                        <FileBase64
                            multiple={false}
                            onDone={(files) => {
                                getFiles(field, files);
                            }}
                            style={{ display: "none" }}
                        />
                    </Button>
                </FormControl>
            );
        }
        if (field.type === "question") {
            return (
                <FormControl fullWidth component="fieldset" key={field.name}>
                    <RadioGroup
                        row={row}
                        aria-label="position"
                        name={field.name}
                        value={`${value && value.id ? value.id : value}`}
                        onChange={onChangeHandle}
                    >
                        <div className="radio-group-title">
                            <IntlMessages
                                defaultMessage={capitalize(field.name)}
                                id={`${intlPrefix || (store.specification ? store.specification.moduleName : "")}.schema.${field.name}`}
                            />
                            {required}:
                        </div>
                        {[
                            { id: "Yes", name: "Да" },
                            { id: "No", name: "Нет" },
                        ].map((item) => (
                            <div>
                                <FormControlLabel
                                    value={`${item.id}`}
                                    control={<Radio color="primary" />}
                                    label={
                                        <IntlMessages
                                            defaultMessage={capitalize(item.name)}
                                            id={`${
                                                intlPrefix ||
                                                (store.specification ? store.specification.moduleName : "")
                                            }.schema.${field.name}.${item.name}`}
                                        />
                                    }
                                />
                            </div>
                        ))}
                    </RadioGroup>
                </FormControl>
            );
        }
        if (field.type === "radio" || field.formType === "radio") {
            // && field.link && field.link.list && field.link.list.length)) { //remove after ||
            return (
                <FormControl fullWidth component="fieldset" key={field.name}>
                    <FormLabel component="legend">
                        <IntlMessages
                            defaultMessage={capitalize(field.name)}
                            id={`${intlPrefix || (store.specification ? store.specification.moduleName : "")}.schema.${field.name}`}
                        />
                        {required}:
                    </FormLabel>

                    <RadioGroup
                        row={row}
                        aria-label="position"
                        name={field.name} /* defaultValue={`${field.link.list[0].id}`} */
                        value={`${value && value.id ? value.id : value}`}
                        onChange={onChangeHandle}
                    >
                        {field.link &&
                            field.link.list &&
                            field.link.list.length &&
                            field.link.list.map((item) => (
                                <FormControlLabel
                                    value={`${item.id}`}
                                    control={<Radio color="primary" />}
                                    label={
                                        <IntlMessages
                                            defaultMessage={capitalize(item.name)}
                                            id={`${
                                                intlPrefix ||
                                                (store.specification ? store.specification.moduleName : "")
                                            }.schema.${field.name}.${item.name}`}
                                        />
                                    }
                                />
                            ))}
                        {field.list &&
                            Array.isArray(field.list) &&
                            field.list.length &&
                            field.list.map((item, key) => (
                                <FormControlLabel value={`${item}`} control={<Radio color="primary" />} label={item} />
                            ))}
                    </RadioGroup>
                </FormControl>
            );
        }
        if (field.type === "boolean") {
            return (
                <FormControl fullWidth component="fieldset" key={field.name}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={value === undefined ? field.default : value}
                                onChange={onChangeCheckboxHandle}
                                name="checkedB"
                                color="primary"
                            />
                        }
                        label={
                            label || (
                                <div>
                                    <IntlMessages
                                        defaultMessage={capitalize(field.name)}
                                        id={`${intlPrefix || (store.specification ? store.specification.moduleName : "")}.schema.${field.name}`}
                                    />
                                    {required}
                                </div>
                            )
                        }
                    />
                </FormControl>
            );
        }
        if (field.type === "dropdown" || field.formType === "select") {
            return (
                <FormControl
                    fullWidth
                    variant={variant}
                    className="is_select_input"
                    style={{ marginTop: 8 }}
                    key={field.name}
                >
                    <InputLabel id="demo-simple-select-label">
                        <IntlMessages
                            defaultMessage={capitalize(field.name)}
                            id={`${intlPrefix || (store.specification ? store.specification.moduleName : "")}.schema.${field.name}`}
                        />
                        {required}
                    </InputLabel>
                    <Select
                        margin="dense"
                        id={field.name}
                        name={field.name}
                        label={
                            label || (
                                <div>
                                    <IntlMessages
                                        defaultMessage={capitalize(field.name)}
                                        id={`${intlPrefix || (store.specification ? store.specification.moduleName : "")}.schema.${field.name}`}
                                    />
                                    {required}
                                </div>
                            )
                        }
                        type={field.type}
                        placeholder={field.placeholder}
                        disabled={disabled}
                        value={
                            value && value.id
                                ? value.id
                                : value === undefined || value === null
                                  ? !!field.multiple || !!multiple
                                      ? []
                                      : ""
                                  : value
                        }
                        onChange={onChangeHandle}
                        onBlur={onBlurHandle}
                        onKeyDown={onKeyDownHandle}
                        fullWidth
                        className={className}
                        multiple={!!field.multiple || !!multiple}
                    >
                        {field.link &&
                            field.link.list &&
                            field.link.list.length &&
                            field.link.list.map((item) => (
                                <MenuItem defaultMessage={item.name} value={item.id}>
                                    {!!field.multiple || !!multiple ? (
                                        item.name
                                    ) : (
                                        <IntlMessages
                                            defaultMessage={item.name}
                                            id={`${
                                                intlPrefix ||
                                                (store.specification ? store.specification.moduleName : "")
                                            }.schema.${field.name}.${item.name}`}
                                        />
                                    )}
                                </MenuItem>
                            ))}

                        {field.list &&
                            Array.isArray(field.list) &&
                            field.list.length &&
                            field.list.map((item, key) => <MenuItem value={item}>!{item}</MenuItem>)}
                    </Select>
                </FormControl>
            );
        }
        if (field.type === "list") {
            // it's good value for type
            const items = value ? [...value, ""] : ["", ""];
            return (
                <FormControl fullWidth key={field.name}>
                    {items.map((count, num) => (
                        <TextField
                            key={`${field.name}_${num}`}
                            margin="dense"
                            id={`${field.name}[${num}]`}
                            name={num}
                            label={
                                label || (
                                    <div>
                                        <IntlMessages
                                            defaultMessage={capitalize(field.name)}
                                            id={`${store.specification ? store.specification.moduleName : ""}.schema.${field.name}.value`}
                                        />
                                        {required}
                                    </div>
                                )
                            }
                            type={field.type}
                            placeholder={field.placeholder}
                            value={value ? JSON.stringify(value[num]) : ""}
                            fullWidth
                            variant={variant}
                            onChange={onChangeHandle}
                            onBlur={onBlurHandle}
                            onKeyDown={onKeyDownHandle}
                            error={errorText !== null && errorText !== undefined}
                            helperText={errorText}
                            disabled={disabled}
                        />
                    ))}
                </FormControl>
            );
        }
        if ((field.type === "number" || field.type === "string") && field.link) {
            const uid = field.name + "_id"; // + id;
            return (
                <FormControl fullWidth variant={variant} className="is_select_input" key={field.name}>
                    <Autocomplete
                        margin="dense"
                        fullWidth
                        open={
                            open &&
                            !store.isFetchingLinkedListInProgress &&
                            (field.link && field.link.straight ? store.list : store.linkedList[uid]) !== undefined
                        }
                        onOpen={async () => {
                            if (store.isFetchingLinkedListInProgress) return;
                            setOpen(true);
                            //if (store.linkedList[uid] === undefined)
                            if (!field.link.straight) {
                                await fetchLinkData(filter, field.name, 15, uid);
                            }
                        }}
                        onFocus={async () => {
                            if (!autoFocus || store.isFetchingLinkedListInProgress) return;
                            if (
                                store.linkedList[uid] === undefined ||
                                (field.link.straight && store.list === undefined)
                            ) {
                                await fetchLinkData(filter, field.name, 15, uid);
                            }
                        }}
                        onClose={() => {
                            setOpen(false);
                            //if (!field.link.static)
                            //store.resetLinkedList(uid);
                        }}
                        onChange={async (event, newValue) => {
                            // add new record. todo. not good! need to show new dialog.
                            if (field.link.isAddSupport && newValue && newValue.id === -1) {
                                newValue = await stores[field.link.store].addRecord({ name: prevSearchValue });
                                delete filter["id"];
                                console.log("fetchLinkData -->4");
                                await fetchLinkData({ $nodx_search: prevSearchValue, ...filter }, field.name, 15, uid);
                            }
                            if (addedOption && newValue?.id !== defaultValue?.id) {
                                setDefaultValue(newValue);
                            }
                            const copyEvent = { ...event };
                            const fieldValue = field?.link?.valueField || "id";
                            if (newValue && newValue[fieldValue]) {
                                copyEvent.target = { name: field.name, value: newValue[fieldValue] };
                                onChangeHandle(copyEvent);
                            } else {
                                copyEvent.target = { name: field.name, value: null };
                                onChangeHandle(copyEvent);
                            }
                        }}
                        onInputChange={async (event, newInputValue) => {
                            if (event && event.type === "change") {
                                if (
                                    (store.linkedList[uid] && store.linkedList[uid].length) ||
                                    (field.link.straight && store.list && store.list.length > 0) ||
                                    !newInputValue.includes(prevSearchValue)
                                ) {
                                    delete filter["id"];
                                    await fetchLinkData(
                                        { $nodx_search: newInputValue, ...filter },
                                        field.name,
                                        15,
                                        uid,
                                    );
                                }
                                setPrevSearchValue(newInputValue);
                            }
                        }}
                        getOptionSelected={(option, value) => {
                            return option.name === value.name;
                        }}
                        getOptionLabel={(option) => (typeof option === "string" ? option || "" : option.name || "")}
                        options={
                            field.link.straight
                                ? [
                                      ...(store.list
                                          ? renderOptionsEmail
                                              ? store.list.map((e) => ({
                                                    ...e,
                                                    name: `${e.name}${OPTION_NAME_SPLITTER}${e.email}`,
                                                }))
                                              : store.list
                                          : []),
                                      ...(addedOption && store.list?.filter((i) => i.id === addedOption?.id).length < 0
                                          ? [addedOption]
                                          : []),
                                  ] || []
                                : store.linkedList[uid] && store.linkedList[uid].length
                                  ? store.linkedList[uid]
                                  : field.link.isAddSupport
                                    ? [{ id: -1, value: prevSearchValue, name: `Добавить: ${prevSearchValue}` }]
                                    : []
                        }
                        defaultValue={defaultValue ? defaultValue || "" : ""}
                        value={defaultValue ? defaultValue || "" : ""}
                        loading={store.isFetchingLinkedListInProgress}
                        disabled={disabled}
                        renderOption={(option) => {
                            const name =
                                renderOptionsEmail && field.link.straight
                                    ? (option.name || "").split(OPTION_NAME_SPLITTER)[0]
                                    : option.name;
                            return (
                                <>
                                    <div className="row" style={{ width: "100%" }}>
                                        {option.avatar !== undefined && !disableOptionAvatars && (
                                            <div className="col-xl-2 col-3">
                                                <div className="chat-avatar-mode">
                                                    <Avatar className="size-40" src={option.avatar} />
                                                </div>
                                            </div>
                                        )}

                                        <div className="col-xl-10 col-9">
                                            <span className="name h4">
                                                {name && (name.length <= 80 ? name : `${name.substring(0, 80)}...`)}
                                            </span>
                                            <div className="chat-info-des">
                                                {option.description &&
                                                    (option.description.length <= 25
                                                        ? option.description
                                                        : `${option.description.substring(0, 25)}...`)}
                                            </div>
                                            {renderOptionsEmail && (
                                                <div className="chat-info-des">
                                                    {option.email &&
                                                        (option.email.length <= 25
                                                            ? option.email
                                                            : `${option.email.substring(0, 25)}...`)}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </>
                            );
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={
                                    label || (
                                        <div>
                                            <IntlMessages
                                                defaultMessage={capitalize(field.name)}
                                                id={`${intlPrefix || (store.specification ? store.specification.moduleName : "")}.schema.${field.name}`}
                                            />
                                            {required}
                                        </div>
                                    )
                                }
                                variant="outlined"
                                style={{
                                    padding: 2,
                                    pointerEvents: store.isFetchingLinkedListInProgress ? "none" : "all",
                                }}
                                InputProps={{
                                    ...params.InputProps,
                                    autoFocus,
                                    disabled,
                                    style: { pointerEvents: store.isFetchingLinkedListInProgress ? "none" : "all" },
                                    endAdornment: (
                                        <>
                                            {store.isFetchingLinkedListInProgress ? (
                                                <CircularProgress color="inherit" size={20} />
                                            ) : null}
                                            {params.InputProps.endAdornment}
                                        </>
                                    ),
                                }}
                                disabled={disabled}
                            />
                        )}
                    />

                    {/*
                    <Select
                        margin="dense"
                        id={field.name}
                        name={field.name}
                        label={(<IntlMessages defaultMessage={capitalize(field.name)}
                                              id={`${store.specification ? store.specification.moduleName : ''}.schema.${field.name}`}/>)}
                        type={field.type}
                        placeholder={field.placeholder}
                        value={value && value.id ? value.id : value}
                        fullWidth

                        onOpen={() => handleSelectOpen(field.name)}
                        onChange={onChangeHandle}
                        onBlur={onBlurHandle}
                        onKeyDown={onKeyDownHandle}
                    >
                        {store.linkedList[field.name] && store.linkedList[field.name].map(item =>
                            <MenuItem value={item.id}>{item.name}</MenuItem>
                        )}
                    </Select>
                    */}
                </FormControl>
            );
        }
        if (field.type === "datetime") {
            return (
                <FormControl
                    fullWidth
                    variant={variant}
                    key={field.name}
                    onClick={(event) => {
                        event.stopPropagation();
                    }}
                >
                    <KeyboardDateTimePicker
                        fullWidth
                        variant={variant}
                        inputVariant={variant}
                        margin="dense"
                        format="YYYY-MM-DD HH:mm"
                        value={value || null}
                        ampm={false}
                        onChange={(date) => {
                            onChangeHandle({ target: { name: field.name, value: date } });
                        }}
                        onBlur={onBlurHandle}
                        onKeyDown={onKeyDownHandle}
                        label={
                            label || (
                                <div>
                                    <IntlMessages
                                        defaultMessage={capitalize(field.name)}
                                        id={`${intlPrefix || (store.specification ? store.specification.moduleName : "")}.schema.${field.name}`}
                                    />
                                    {required}
                                </div>
                            )
                        }
                        placeholder={moment(value).format("YYYY-MM-DD HH:mm")}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <i className="zmdi zmdi-alarm" />
                                </InputAdornment>
                            ),
                            disabled,
                        }}
                    />
                </FormControl>
            );
        }
        if (field.type === "date") {
            return (
                <FormControl
                    fullWidth
                    variant={variant}
                    key={field.name}
                    onClick={(event) => {
                        event.stopPropagation();
                    }}
                >
                    <KeyboardDatePicker
                        fullWidth
                        variant={variant}
                        inputVariant={variant}
                        margin="dense"
                        format="YYYY-MM-DD"
                        value={value || null}
                        onChange={(date) => {
                            onChangeHandle({ target: { name: field.name, value: date && date.format("YYYY-MM-DD") } });
                        }}
                        onBlur={onBlurHandle}
                        onKeyDown={onKeyDownHandle}
                        label={
                            label || (
                                <div>
                                    <IntlMessages
                                        defaultMessage={capitalize(field.name)}
                                        id={`${intlPrefix || (store.specification ? store.specification.moduleName : "")}.schema.${field.name}`}
                                    />
                                    {required}
                                </div>
                            )
                        }
                        placeholder={moment().format("YYYY-MM-DD")}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <i className="zmdi zmdi-alarm" />
                                </InputAdornment>
                            ),
                        }}
                    />
                </FormControl>
            );
        }
        if (field.type === "time") {
            return (
                <FormControl
                    fullWidth
                    variant={variant}
                    key={field.name}
                    onClick={(event) => {
                        event.stopPropagation();
                    }}
                >
                    <KeyboardTimePicker
                        fullWidth
                        variant={variant}
                        inputVariant={variant}
                        margin="dense"
                        format="hh:mm"
                        ampm={false}
                        value={value || null}
                        onChange={(date) => {
                            onChangeHandle({ target: { name: field.name, value: date } });
                        }}
                        onBlur={onBlurHandle}
                        onKeyDown={onKeyDownHandle}
                        label={
                            label || (
                                <div>
                                    <IntlMessages
                                        defaultMessage={capitalize(field.name)}
                                        id={`${intlPrefix || (store.specification ? store.specification.moduleName : "")}.schema.${field.name}`}
                                    />
                                    {required}
                                </div>
                            )
                        }
                        placeholder={moment().format("hh:mm")}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <i className="zmdi zmdi-alarm" />
                                </InputAdornment>
                            ),
                        }}
                    />
                </FormControl>
            );
        }
        if (field.type === "unixtime") {
            return (
                <FormControl
                    fullWidth
                    variant={variant}
                    key={field.name}
                    onClick={(event) => {
                        event.stopPropagation();
                    }}
                >
                    <KeyboardDateTimePicker
                        fullWidth
                        variant={variant}
                        inputVariant={variant}
                        margin="dense"
                        ampm={false}
                        format="YYYY-MM-DD HH:mm"
                        value={value ? moment.unix(value).format("YYYY-MM-DD HH:mm") : null}
                        onChange={(date) => {
                            onChangeHandle({ target: { name: field.name, value: moment(date).unix() } });
                        }}
                        onBlur={onBlurHandle}
                        onKeyDown={onKeyDownHandle}
                        label={
                            label || (
                                <div>
                                    <IntlMessages
                                        defaultMessage={capitalize(field.name)}
                                        id={`${intlPrefix || (store.specification ? store.specification.moduleName : "")}.schema.${field.name}`}
                                    />
                                    {required}
                                </div>
                            )
                        }
                        placeholder={moment(value).format("YYYY-MM-DD HH:mm")}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <i className="zmdi zmdi-alarm" />
                                </InputAdornment>
                            ),
                        }}
                    />
                </FormControl>
            );
        }
        if (field.type === "email") {
            return (
                <FormControl fullWidth key={field.name}>
                    <TextField
                        margin="dense"
                        id={field.name}
                        name={field.name}
                        label={
                            label || (
                                <div>
                                    <IntlMessages
                                        defaultMessage={capitalize(field.name)}
                                        id={`${intlPrefix || (store.specification ? store.specification.moduleName : "")}.schema.${field.name}`}
                                    />
                                    {required}
                                </div>
                            )
                        }
                        type="text"
                        placeholder={field.placeholder}
                        value={value}
                        onChange={onChangeHandle}
                        onBlur={onBlurHandle}
                        onKeyDown={onKeyDownHandle}
                        fullWidth
                        variant={variant}
                        error={errorText !== null && errorText !== undefined}
                        helperText={errorText}
                    />
                </FormControl>
            );
        }
        if (field.type === "phone") {
            return (
                <FormControl fullWidth key={field.name}>
                    <InputMask
                        mask={field.mask}
                        value={val}
                        onChange={(e) => setVal(e.target.value)}
                        onBlur={onBlurHandle}
                        onKeyDown={onKeyDownHandle}
                        className={disabled && "disabled"}
                    >
                        {(inputProps) => (
                            <TextField
                                {...inputProps}
                                disableUnderline
                                margin="dense"
                                id={field.name}
                                name={field.name}
                                label={
                                    label || (
                                        <div>
                                            <IntlMessages
                                                defaultMessage={capitalize(field.name)}
                                                id={`${intlPrefix || (store.specification ? store.specification.moduleName : "")}.schema.${field.name}`}
                                            />
                                            {required}
                                        </div>
                                    )
                                }
                                type="tel"
                                placeholder={field.placeholder}
                                fullWidth
                                variant={variant}
                                error={errorText !== null && errorText !== undefined}
                                helperText={errorText}
                            />
                        )}
                    </InputMask>
                </FormControl>
            );
        }
        if (field.formType === "html") {
            return (
                <FormControl fullWidth variant={variant} className="is_select_input" key={field.name}>
                    <InputLabel id="demo-simple-select-label">
                        <IntlMessages
                            defaultMessage={capitalize(field.name)}
                            id={`${intlPrefix || (store.specification ? store.specification.moduleName : "")}.schema.${field.name}`}
                        />
                        {required}
                    </InputLabel>
                    <br />
                    <br />
                    {/*
                    <CKEditor
                        id={field.name}
                        name={field.name}
                        content={value}
                        events={{
                            //'blur': onBlurHandle,
                            //'afterPaste':afterPaste,
                            'change': (event) => onEditorChangeHandle(event,field.name)
                        }}
                    />
                        */}

                    <CKEditor data={value} onChange={(event) => onEditorChangeHandle(event, field)} />

                    {/*
                    <Editor editorStyle={{
                        width: '100%',
                        minHeight: 100,
                        borderWidth: 1,
                        borderStyle: 'solid',
                        borderColor: 'lightgray'
                    }}
                            editorState={editorState}
                            wrapperClassName="demo-wrapper"
                            onEditorStateChange={(editorState) => setEditorState(editorState)}
                    />
                    */}
                </FormControl>
            );
        }
        if (field.type === "location") {
            return (
                <FormControl fullWidth variant={variant} className="is_select_input is-location-label" key={field.name}>
                    <GoogleMapAutoComplete
                        showMap={field.showMap}
                        value={value && value.address ? value.address : ""}
                        field={field}
                        location={
                            value && value.lat && value.lng
                                ? [parseFloat(value.lat), parseFloat(value.lng)]
                                : [55.75, 37.6167]
                        }
                        variant={variant}
                        onChange={onMapChangeHandle}
                        label={
                            label || (
                                <div>
                                    <IntlMessages
                                        defaultMessage={capitalize(field.name)}
                                        id={`${intlPrefix || (store.specification ? store.specification.moduleName : "")}.schema.${field.name}`}
                                    />
                                    {required}
                                </div>
                            )
                        }
                    />
                </FormControl>
            );
        }
        if (field.type === "location_polygon") {
            return (
                <FormControl fullWidth variant={variant} className="is_select_input" key={field.name}>
                    <GoogleMapSelectPolygon
                        showMap={field.showMap}
                        value={value}
                        field={field}
                        location={value && value.length && value[0] ? [value[0][1], value[0][0]] : [55.75, 37.6167]}
                        variant={variant}
                        onChange={onMapPolygonChangeHandle}
                        label={
                            label || (
                                <div>
                                    <IntlMessages
                                        defaultMessage={capitalize(field.name)}
                                        id={`${intlPrefix || (store.specification ? store.specification.moduleName : "")}.schema.${field.name}`}
                                    />
                                    {required}
                                </div>
                            )
                        }
                    />
                </FormControl>
            );

            /*
        } else if (field.type === "taxi_tariff" ) { //TODO:remove from here!!!!!!!!!!!!!!!!!!!!!! add property to render curtom specification type

            return (

                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label"><IntlMessages defaultMessage={capitalize(field.name)}
                                                                            id={`${store.specification ? store.specification.moduleName : ''}.schema.${field.name}`}/>{required}</InputLabel>

                    {["start_km", "start_time", "start_price", "time_price", "km_price"].map((item,num)=> {
                            return (
                                <TextField
                                    key={`${field.name}_${num}`}
                                    margin="dense"
                                    id={`${field.name}[${num}]`}
                                    name={num}
                                    label={label ? label : (<div><IntlMessages defaultMessage={capitalize(field.name)}
                                                               id={`${store.specification ? store.specification.moduleName : ''}.schema.${field.name}.value`}/>{required}</div>)}
                                    type={field.type}
                                    placeholder={field.placeholder}
                                    value={value ? value[num] : ""}
                                    fullWidth
                                    variant={variant}

                                    onChange={onChangeHandle}
                                    onBlur={onBlurHandle}
                                    onKeyDown={onKeyDownHandle}

                                    error={errorText !== null && errorText !== undefined}
                                    helperText={errorText}
                                />);
                        }
                    )}
                </FormControl>
            );
*/
        } else if (field.type === "json") {
            return (
                <FormControl fullWidth key={field.name}>
                    <TextField
                        multiline={true}
                        rows={4}
                        margin="dense"
                        id={field.name}
                        name={field.name}
                        label={
                            label || (
                                <div>
                                    <IntlMessages
                                        defaultMessage={capitalize(field.name)}
                                        id={`${intlPrefix || (store.specification ? store.specification.moduleName : "")}.schema.${field.name}`}
                                    />
                                    {required}
                                </div>
                            )
                        }
                        type={field.type}
                        placeholder={field.placeholder}
                        value={typeof value === "object" ? JSON.stringify(value) : value}
                        fullWidth
                        variant={variant}
                        onChange={onChangeHandle}
                        onBlur={onBlurHandle}
                        onKeyDown={onKeyDownHandle}
                        error={errorText !== null && errorText !== undefined}
                        helperText={errorText}
                        disabled={disabled}
                    />
                </FormControl>
            );
        }

        return (
            <FormControl fullWidth key={field.name}>
                <TextField
                    multiline={field.formType === "textarea" || field.type === "text"}
                    rows={field.formType === "textarea" || field.type === "text" ? field.formRows || 4 : 1}
                    margin="dense"
                    id={field.name}
                    name={field.name}
                    label={
                        label || (
                            <div>
                                <IntlMessages
                                    defaultMessage={capitalize(field.name)}
                                    id={`${intlPrefix || (store.specification ? store.specification.moduleName : "")}.schema.${field.name}`}
                                />
                                {required}
                            </div>
                        )
                    }
                    type={field.type}
                    placeholder={field.placeholder}
                    value={val}
                    fullWidth
                    variant={variant}
                    onChange={(e) => setVal(e.target.value)}
                    onBlur={onBlurHandle}
                    onKeyDown={onKeyDownHandle}
                    error={errorText !== null && errorText !== undefined}
                    helperText={errorText}
                    disabled={disabled}
                    onWheel={(e) => {
                        e.target.blur();
                        e.preventDefault();
                    }}
                />
            </FormControl>
        );
    });
};

export default EditField;
