import { decorate, observable, action, runInAction, toJS, computed } from 'mobx';
//import { StoreInterface, RootStoreInterface } from 'interfaces';
import arrayMove from "array-move";
import { NotificationManager } from 'react-notifications';
import { getErrorMessage } from 'modules/WebApi';
import { loadFile } from 'util/loadFile';

class CommonStore implements StoreInterface {
    rootStore: RootStoreInterface;

    apiClient = undefined;

    isWebSocketUpdates = true;

    specification = undefined;

    isCheckingExportInProggress = false;
    checkExportIntervalId = undefined;
    exportData = undefined;

    isCheckingImportInProggress = false;
    checkImportIntervalId = undefined;
    importtData = undefined;
    isFetchingImportInProgress = false;

    //listLoadCallbacks = [];
    list = undefined;
    total = 0;
    filter = {};
    orderBy = "";
    isFetchingListInProgress = false;
    fetchingListError: string;

    isSubscribeWSListInProgress = false;
    SubscribeWSListError: string;

    record = undefined;
    isFetchingRecordInProgress = false;
    fetchingRecordError: string;

    isAddingInProgress: boolean = false;
    addingError: string;

    isDeletingInProgress: boolean = false;
    deletingError: string;

    isSavingSettingInProgress: boolean = false;
    savingSettingsError: string;

    isFetchingSettingInProgress: boolean = false;
    fetchingSettingsError: string;

    linkedList = {};
    isFetchingLinkedListInProgress = false;
    fetchingLinkedListError: string;


    constructor(rootStore: RootStoreInterface, apiClient, specification, isWebSocketUpdates = true) {
        this.rootStore = rootStore;
        this.apiClient = apiClient;
        this.isWebSocketUpdates = isWebSocketUpdates ;
        if (!specification && this.constructor.name !== 'UsersStore' && this.constructor.name !== 'SpecificationsStore') {
            console.error("Specification isn't defined: " + this.constructor.name);
            //alert("Specification isn't defined: " + this.constructor.name );
        }
        //alert(specification);
        this.specification = specification;
        if (this.specification && this.specification.url)
            this.apiClient.setSchemaUrl(this.specification.url);
    }

    /*
    //TODO: remove from here.
    async fetchSpecification(useCache=true): Promise<void> {
        if ( useCache && this.specification ) {
            return;
        }

        try {
            const data = await this.apiClient.specification();
            runInAction(() => {
                this.specification = { ... data};
            });
        } catch (err) {
            alert( getErrorMessage(err) );
        }
    }
    */

    /*
    async subscribeWS (filter): Promise<void> {
        runInAction(() => {
            this.fetchingSubscribeWSError = undefined;
            this.isFetchingSubscribeWSInProgress = true;
        });
        try {
            const data = await this.apiClient.subscribeWS(this.filter);
            runInAction(() => {
                this.isFetchingSubscribeWSInProgress = false;
            });
        } catch (err) {
            console.error(err);
            runInAction(() => {
                this.fetchingSubscribeWSError = getErrorMessage(err);
                this.isFetchingSubscribeWSInProgress = false;
            });
        }
    }
    */

    async dispatch(item, type, isShift = true) {
        console.log("DISPATCH IN STORE", this.schema, type, item, isShift);
        runInAction(() => {
            if (!item) return;
            if (!this.record) {
                this.record = this.recordHandlers({ ...item });
            }
            if (!item.id) {
                item.id = this.record.id
            }
            if (this.record.id === item.id) {
                this.record = this.recordHandlers({ ...this.record, ...item });
                this.recordUpdated(this.record);
            }
            if (this.fetchingListError !== 'Forbidden') {
                if (!this.list) {
                    console.log(2);
                    this.list = [];
                }
                //TODO
                if (type === "delete") {
                    this.list = this.list.filter(element => element.id !== item.id);
                    return;
                }

                let found = false;
                this.list.find((element, index) => {
                    //console.log(element.id, item.id, element.id === item.id);
                    if (element.id == item.id) {
                        this.list[index] = this.recordHandlers({ ...this.list[index], ...item });
                        found = true;
                        console.log("UPDATE");
                    }
                })
                if (!found) {
                    console.log("NEW");
                    //this.list.count++;
                    if (isShift) {
                        this.list.unshift(this.recordHandlers({ ...item }));
                    } else {
                        this.list.push(this.recordHandlers({ ...item }));
                    }
                }

                if (this.record.id !== item.id) {
                    this.recordUpdated(this.list.find(element => element.id === item.id));
                }
            }
        });
    }

    async recordUpdated(record) {

    }

    getFilter() {
        return toJS(this.filter);
    }

    async setFilter(filter = {}, queryParams = {}) {
        const params = [
            queryParams.useCache || false,
            { ...this.filter, ...filter },
            queryParams.orderBy || undefined,
            queryParams.page || 0,
            queryParams.limit || 200,
            queryParams.isDetail || false,
            queryParams.isAppend || false
        ]
        await this.fetchList(...params);
    }

    resetFilter() {
        runInAction(() => {
            this.filter = {}
        })
    }


    async reloadList() {
        this.fetchList(false, this.filter, this.orderBy, this.page, this.limit, this.isDetail);
    }

    async resortList(oldId, newId) {
        runInAction(() => {
            const oldIndex = this.list.findIndex(item => item.id === oldId);
            const newIndex = this.list.findIndex(item => item.id === newId);
            this.list = arrayMove(this.list, oldIndex, newIndex);
        })
    }

    async fetchList(useCache, _filter, orderBy, page, limit, isDetail = false, isAppend = false, urlVersion = 1): Promise<void> {
        let { orFields, ...filter} = _filter || {};
        if (orFields && filter[orFields.from]) {
            const value = filter[orFields.from];
            delete filter[orFields.from];
            filter = {
                "$and": [
                    {
                        ...filter,
                    },
                    {
                        "$or": orFields.to.map(f => ({
                            [f]: [
                                "like",
                                value
                            ]
                        }))
                    }
                ]
            }
        }
        //alert("FETCH LIST");
        if (useCache === true && this.list) {
            return;
        }

        runInAction(() => {
            this.filter = filter || {};
            this.orderBy = orderBy || undefined;
            this.page = page || 0;
            this.limit = limit || 200;
            this.isDetail = isDetail || false;
            this.fetchingListError = undefined;
            this.isFetchingListInProgress = true;
        });

        try {
            const { items, total } = await this.apiClient.list(this.filter, this.orderBy, this.page, this.limit, isDetail, urlVersion);
            runInAction(() => {

                if (isAppend) {
                    this.list = [...this.list, ...items];
                } else {
                    this.list = [...items];
                }
                this.total = total;

                if (this.isWebSocketUpdates)
                  this.rootStore.modulesStore.webSocketStore.subscribe(this.specification.moduleName, "list", this.filter);

                if (this.list && this.list.length) {
                    this.list = this.list.map(record => this.recordHandlers(record));
                }

                this.isFetchingListInProgress = false;

                /*
                this.listLoadCallbacks.forEach(callback=>{
                    callback(this.list);
                })
                 */


            });
            return [...items];
        } catch (err) {
            console.log(err);
            runInAction(() => {
                this.fetchingListError = err;
                this.isFetchingListInProgress = false;
            });
        }
    }

    /*
    onListLoad (callback) {
        this.listLoadCallbacks.push(callback);
    }
     */

    resetExportData() {
        runInAction(() => {
            this.exportData = undefined
        })
    }

    async fetchExport(filter, orderBy, fields, fileType) {
        runInAction(() => {
            this.fetchingExportError = ""
            this.isFetchingExportInProgress = true;
        });
        const moduleName = this.moduleName === 'CustomersReports' ? 'Customers' : this.moduleName
        try {
            const data = await this.apiClient.export(moduleName, filter, orderBy, fields, fileType);
            if (data?.status === 'success' && data?.data?.report?.status === 'new' && data?.data?.report?.id) {
                runInAction(() => {
                    this.checkExportIntervalId = setInterval(() => {
                        this.checkExport(data.data.report.id)
                    }, 5000)
                })
                return data
            }
            runInAction(() => {
                this.isFetchingExportInProgress = false;
            });
            return data;
        } catch (err) {
            console.log(err);
            runInAction(() => {
                this.fetchingExportError = err;
                this.isFetchingExportInProgress = false;
            });
        }
    }

    async checkExport(id) {
        if (this.isCheckingExportInProggress) return;
        runInAction(() => {
            this.isCheckingExportInProggress = true
        })
        try {
            const response = await this.apiClient.checkExport(id);
            const { report } = response || {}
            runInAction(() => {
                this.exportData = {
                    ...(this.exportData || {}),
                    ...(report || {})
                };
                if (!report || report.status === 'fail') {
                    clearInterval(this.checkExportIntervalId);
                    this.resetExportData();
                    NotificationManager.error('Error: ' + getErrorMessage(report?.error_message));
                    runInAction(() => {
                        this.isFetchingExportInProgress = false;
                    });
                }
                if (report?.link && report?.status === 'ready') {
                    clearInterval(this.checkExportIntervalId);
                    try {
                        loadFile(report.link, this.moduleName || 'report')
                    } catch (er) {
                        NotificationManager.error('Error: ' + getErrorMessage(er?.error_message || er?.toString()));
                    }
                    runInAction(() => {
                        this.isFetchingExportInProgress = false;
                    });
                    this.resetExportData();
                }
            })
        } catch (err) {
            clearInterval(this.checkExportIntervalId);
            this.resetExportData();
            runInAction(() => {
                this.isFetchingExportInProgress = false;
            });
            NotificationManager.error('Error: ' + getErrorMessage(err));
        }
        runInAction(() => {
            this.isCheckingExportInProggress = false
        })
    }

    onImportRequestSuccess(response, onSuccess, onError) {
        this.setImportData(response);
        this.setIsFetchingImportInprogress(true);
        runInAction(() => {
            this.checkImportIntervalId = setInterval(() => {
                this.checkImport(response.data.id, onSuccess, onError)
            }, 5000)
        })
    }

    onImportRequestError() {
        this.setIsFetchingImportInprogress(false);
    }

    resetImportData() {
        runInAction(() => {
            this.importData = undefined
        })
    }

    async checkImport(id, onSuccess, onError) {
        if (this.isCheckingImportInProggress) return;
        runInAction(() => {
            this.isCheckingImportInProggress = true
        })
        try {
            const response = await this.apiClient.checkImport(id);
            runInAction(() => {
                this.importData = {
                    ...(this.importData || {}),
                    ...(response || {})
                };
                if (!response || response.status === 'fail') {
                    onError && onError();
                    clearInterval(this.checkImportIntervalId);
                    this.resetImportData();
                    this.setIsFetchingImportInprogress(false);
                }
                if (response?.status === 'ready') {
                    onSuccess && onSuccess();
                    clearInterval(this.checkImportIntervalId);
                    this.setIsFetchingImportInprogress(false);
                    // this.resetImportData();
                }
            })
        } catch (err) {
            onError && onError();
            clearInterval(this.checkImportIntervalId);
            this.resetImportData();
            this.setIsFetchingImportInprogress(false);
            NotificationManager.error('Error: ' + getErrorMessage(err));
        }
        runInAction(() => {
            this.isCheckingImportInProggress = false
        })
    }

    setIsFetchingImportInprogress(isInpogress = false) {
        runInAction(() => {
            this.isFetchingImportInProgress = isInpogress;
        })
    }

    setImportData(data = {}) {
        runInAction(() => {
            this.importData = {
                ...(this.importData || {}),
                ...data
            };
        })
    }

    async fetchImportSample(fileType): Promise<void> {
        try {
            return await this.apiClient.importSample(fileType);
        } catch (err) {
            console.log(err);
            runInAction(() => {
                this.fetchingImportSampleError = err;
            });
        }
    }



    async fetchRecord(id: number, detail = false, isUpdatingBackground = false): Promise<void> {
        runInAction(() => {
            this.fetchingRecordError = undefined;
            if (!isUpdatingBackground) {
                this.record = undefined;
                this.isFetchingRecordInProgress = true;
            }
        });
        try {
            const data = await this.apiClient.get(id, detail);
            if (this.isWebSocketUpdates)
              this.rootStore.modulesStore.webSocketStore.subscribe(this.specification.moduleName, "record", { id: data.id });

            this.dispatch(data, "fetch");
            runInAction(() => {
                this.isFetchingRecordInProgress = false;

            });
            return data
        } catch (err) {
            runInAction(() => {
                this.record = undefined;
                this.fetchingRecordError = err;
                this.isFetchingRecordInProgress = false;
            });
        }
    }


    async addRecord(params, isShift = true): Promise<void> {
        delete params["$nodx_search"];

        runInAction(() => {
            this.addingError = '';
            this.isAddingInProgress = false;
        });

        try {
            const data = await this.apiClient.add({ ...params });
            this.dispatch(data, "new", isShift);
            runInAction(() => {
                this.isAddingInProgress = false;
            });
            return data;
        } catch (err) {
            runInAction(() => {
                this.addingError = err;
                this.isAddingInProgress = false;
            });
        }
    }


    async updateRecord(id, record): Promise<void> {
        console.log("DATA", id, record);
        //const prevRecord = this.record && this.record.id === id ? this.record :
        //    this.list && this.list ? this.list.find(item => item.id == id) : {};

        runInAction(() => {
            this.addingError = '';
            this.isAddingInProgress = false;
            //this.dispatch(record, "update");
        });

        try {

            const data = await this.apiClient.update(id, record);
            this.dispatch(data, "update");
            runInAction(() => {
                this.isAddingInProgress = false;
            });
            return data;

        } catch (err) {
            runInAction(() => {
                //this.dispatch(prevRecord, "update");
                this.addingError = err;
                this.isAddingInProgress = false;
            });
        }
    }


    async deleteRecord(id, params): Promise<void> {
        runInAction(() => {
            this.deletingError = '';
            this.isDeletingInProgress = false;
        });

        try {
            const data = await this.apiClient.delete(id);
            //TODO: dispatch?
            this.dispatch({ id }, "delete");
            runInAction(() => {
                this.isDeletingInProgress = false;
            });
            return data;
        } catch (err) {
            runInAction(() => {
                this.deletingError = err;
                this.isDeletingInProgress = false;
            });
        }
    }

    /*
    async deleteRecord2(id, params): Promise<void> {
        runInAction(() => {
            this.deletingError = '';
            this.isDeletingInProgress = false;
        });

        try {
            const data = await this.apiClient.delete();
            //TODO: dispatch?
            this.dispatch({id}, "delete");
            runInAction(() => {
                this.isDeletingInProgress = false;
            });
            return data;
        } catch (err) {
            runInAction(() => {
                this.deletingError = err;
                this.isDeletingInProgress = false;
            });
        }
    }
     */


    recordHandlers(record) {
        record = this.formatRecord(record);

        record.reload = async () => {
            this.fetchRecord(record.id);
        }
        record.update = async (data) => {
            return this.updateRecord(record.id, data);
        }
        record.delete = async () => {
            this.deleteRecord(record.id);
        }

        return record;
    }

    formatRecord(record) {
        return record;
    }




    async fetchLinkedList(fieldName: string = "", filter: object = {}, uid: string = undefined, limit = undefined): Promise<void> {
        //if ( this.linkedList[fieldName] ) { //TODO. use cache?
        //    return;
        //}
        runInAction(() => {
            this.fetchingLinkedListError = undefined;
            this.isFetchingLinkedListInProgress = true;
        });
        try {
            const data = await this.apiClient.linkedList(fieldName, filter, limit);
            runInAction(() => {
                this.linkedList[uid ? uid : fieldName] = [...data];

                this.isFetchingLinkedListInProgress = false;
            });
        } catch (err) {
            runInAction(() => {
                this.fetchingLinkedListError = err;
                this.isFetchingLinkedListInProgress = false;
            });
        }
    }



    async resetLinkedList(fieldName: string = ""): Promise<void> {
        runInAction(() => {
            delete this.linkedList[fieldName];
        })
    }

    setEditingRecord(record) {
        runInAction(() => {

            this.editingRecord = { ...record };
            delete this.editingRecord.ctime;
            delete this.editingRecord.mtime;
            delete this.editingRecord.cuser_id;
            delete this.editingRecord.muser_id;
        });
    }

    /*
    setOrderBy (orderBy) {
        runInAction(() => {
            this.orderBy = orderBy;
        }
    }

     */

    reset() {
        runInAction(() => {
            this.list = undefined;
            this.record = undefined;
        });
    }
    get moduleName() {
        return this.specification.moduleName || ''
    }

}





decorate(CommonStore, {
    //fetchSpecification: action,
    specification: observable,

    fetchList: action,
    list: observable,
    total: observable,
    filter: observable,
    orderBy: observable,
    isFetchingListInProgress: observable,
    fetchingListError: observable,

    isCheckingImportInProggress: observable,
    checkImportIntervalId: observable,
    importData: observable,
    isFetchingImportInProgress: observable,
    resetImportData: action,
    setImportData: action,

    fetchingExportError: observable,
    isFetchingExportInProgress: observable,
    isCheckingExportInProggress: observable,
    exportData: observable,

    subscribeWS: action,
    isSubscribeWSListInProgress: observable,
    SubscribeWSListError: observable,

    fetchLinkedList: action,
    linkedList: observable,
    isFetchingLinkedListInProgress: observable,
    fetchingLinkedListError: observable,

    fetchRecord: action,
    record: observable,
    isFetchingRecordInProgress: observable,
    fetchingRecordError: observable,

    addRecord: action,
    isAddingInProgress: observable,
    addingError: observable,

    deleteRecord: action,
    isDeletingInProgress: observable,
    deletingError: observable,

    fetchSettings: action,
    isFetchingSettingsInProgress: observable,
    fetchingSettingsError: observable,

    saveSettings: action,
    isSavingSettingsInProgress: observable,
    savingSettingsError: observable,

    setEditingRecord: action,
    editingRecord: observable,
    moduleName: computed
});


export default CommonStore;
