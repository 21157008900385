import { AxiosInstance } from 'axios';

class CommonCRUD {
    api: AxiosInstance;
    schema = undefined;
    version = 1;

    constructor(api, version = 1) {
        this.api = api;
        this.version = version;
    }

    setSchemaUrl(schema) {
        this.schema = schema;
    }


    async list(filter, orderBy, page, limit, detail, vers) {
        const urlVersion = vers || this.version;
        return await this.api.fetch({
            method: 'post',
            url: `/${this.schema}/list${urlVersion === 2 ? '/web' : ''}`,
            urlVersion,
            data: {
                filter,//:JSON.stringify(filter),
                orderBy,
                page,
                limit,
                detail: detail ? 1 : 0,
                ...(urlVersion === 2
                    ? {
                        orderField: filter?.orderField || (filter?.orderBy?.split(' ') || [])[0] || 'id',
                        orderType: filter?.orderType || (filter?.orderBy?.split(' ') || [])[1] || 'desc',
                    }
                    : {
                        orderBy: filter?.orderBy || orderBy || `${filter?.orderField || 'id'} ${filter?.orderType || 'desc'}`,
                    }),
            }
        });
    }

    async export(moduleName, filter, orderBy, fields, fileType) {
        return await this.api.fetch({
            method: 'post',
            url: '/reports',
            urlVersion: this.version,
            data: {
                moduleName,
                filter,
                orderBy,
                fields,
                fileType
            },
            returnBody: true
        }, true);
    }

    async checkExport(id) {
        return await this.api.fetch({
            method: 'get',
            url: `/reports/${id}`,
            urlVersion: this.version,
        })
    }

    async checkImport(id) {
        return await this.api.fetch({
            method: 'get',
            url: `/orders/import/${id}`,
            urlVersion: this.version,
        })
    }
    /*
        async import(filter, orderBy, fileType) {
            return await this.api.fetch({
                method:'post',
                url:`/${this.schema}/import`,
                data:{
                    filter,
                    orderBy,
                    fileType
                },
                returnBody: true
            });
        }
        */

    async importSample(fileType) {
        return await this.api.fetch({
            method: 'post',
            url: `/${this.schema}/import-sample`,
            urlVersion: this.version,
            data: { fileType },
            returnBody: true
        });
    }

    async linkedList(fieldName, filter, limit) {
        return await this.api.fetch({
            method: 'post',
            url: `/${this.schema}/linked-list/${fieldName}`,
            data: {
                filter,
                ...limit ? { limit } : {}
            }

        });
    }

    async get(id, detail) {
        return await this.api.fetch({
            method: 'get',
            url: `/${this.schema}/${id}?detail=${detail ? 1 : 0}`,
            urlVersion: this.version,
        });
    }

    async add(data) {
        return await this.api.fetch({
            method: 'post',
            url: `/${this.schema}`,
            urlVersion: this.version,
            data
        });
    }

    async update(id, data) {
        return await this.api.fetch({
            method: 'put',
            url: `/${this.schema}/${id}`,
            urlVersion: this.version,
            data
        });
    }

    async delete(id) {
        return await this.api.fetch({
            method: 'delete',
            url: `/${this.schema}/${id}`,
            urlVersion: this.version,
        });
    }


    /*
    async subscribeWS(filter) {
        return await this.api.fetch({
            method:'get',
            url:`/${this.schema}/subscribeWS?filter=${JSON.stringify(filter)}`
        });
    }
    */

}

export default CommonCRUD;
